<template>
  <div class="reset-password-page">
    <!-- Le logo et le titre -->
    <md-empty-state md-icon="vpn_key" md-label="Choisissez votre mot de passe">
      <!-- La zone de debug -->
      <pre v-if="isDebug" class="debug-log">
				errors :
				{{ errors }}

				form :
				{{ form }}
			</pre
      >

      <!-- Nouveau mot de passe -->
      <md-field :class="{ 'md-invalid': errors.has('newPassword') }">
        <label>Nouveau mot de passe *</label>
        <md-input
          v-model="form.newPassword"
          v-validate="{ required: true, min: 6 }"
          name="newPassword"
          type="password"
        >
        </md-input>
        <span class="md-error" v-show="errors.has('newPassword')">
          {{ errors.first("newPassword") }}
        </span>
      </md-field>

      <!-- Confirmer nouveau mot de passe -->
      <md-field :class="{ 'md-invalid': errors.has('confirmNewPassword') }">
        <label>Confirmer nouveau mot de passe *</label>
        <md-input
          v-model="form.confirmNewPassword"
          v-validate="{ required: true, is: form.newPassword }"
          name="confirmNewPassword"
          type="password"
        >
        </md-input>
        <span class="md-error" v-show="errors.has('confirmNewPassword')">
          {{ errors.first("confirmNewPassword") }}
        </span>
      </md-field>

      <!-- Le bouton d'envoi -->
      <md-button
        class="md-primary md-raised reset-password-button"
        @click.native="resetPassword"
      >
        Envoyer
      </md-button>

      <!-- Retour à la connexion -->
      <router-link to="/signin-admin">
        Se connecter
      </router-link>
    </md-empty-state>
  </div>
</template>

<script>
import loginModel from "../../model/login";

export default {
  data() {
    return {
      isDebug: false,
      form: {
        newPassword: "",
        confirmNewPassword: "",
        token: this.$route.params.token,
      },
    };
  },
  methods: {
    resetPassword() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          loginModel
            .resetPasswordAdmin(this.form)
            .then(() => {
              this.$toasted.global.AppSucces({
                message: "Mot de passe modifié avec succès",
              });

              this.$router.push("/signin-admin");
            })
            .catch((err) => {
              this.$toasted.global.AppError({
                message: err.msg,
              });
            });
        } else {
          this.$toasted.global.AppInfo({
            message: "Vous devez remplir les champs manquants",
          });
        }
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!to.params.token) next(from.path);
    next();
  },
};
</script>

<style lang="scss" scoped>
.reset-password-page {
  height: 100vh;
  display: flex;
  justify-content: space-around;

  .reset-password-button {
    width: 90%;
  }

  a {
    margin-top: 16px;
  }
}
</style>
